import React, { Component } from "react";
import { connect } from "react-redux";
import { notify } from "../../../redux/actions";
import { noWhitespace } from "../../../utils/rules";
import { withTranslation } from "react-i18next";
import { Col, Input, Modal, Form, Row, Button, Select, DatePicker } from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Upload } from "antd";
import { aictia } from "../../../const/api";
import JoditEditor from "jodit-react";
import moment from "moment";

const { Option } = Select;

class EditStrategicPartnership extends Component {
  formRef = React.createRef();
  editor = React.createRef();
  mainUrl = "LegalNormsAndStandard";
  id = this.props.match.params.id;
  constructor(props) {
    super(props);
  }

  state = {
    aboutt: {},
   
    trigger: false,
    categories: [],
  };

 

  componentDidMount() {
       if (this.id) {
      this.formRef.current.resetFields();
      let obj = {};
      let datas = {};
      const getPost = async () => {
        await aictia.get(this.mainUrl + "/" + this.id).then((res) => {
 
          obj["legalNormsAndStandardMenuId"] = res.data.legalNormsAndStandardMenuId;
          obj["number"] = res.data.number;
          obj["link"] = res.data.link;
          obj[`date`] = moment(res.data.date)
          res.data.legalNormsAndStandardLanguageList.forEach((d) => {
            obj[`content_${d.language}`] = d.content;
            datas[`content_${d.language}`] = d.content;
          });
          this.setState({
            aboutt: datas,
          });
          console.log(obj);
          if (this.formRef.current !== null) {
            this.formRef.current.setFieldsValue(obj);
          }
        });
      };
      getPost();
    }
    const getCategories = async () => {
      await aictia.get("LegalNormsAndStandardsMenu").then((res) => {
        this.setState({
          categories: res.data.map((s) => {
            return {
              ...s,
            };
          }),
        });
      });
    };
    getCategories();
  }
  saveItem = async (values) => {
    console.log(values);
    const langs = [
      {
        name: "Azerbaijan",
        key: "az",
        id: 1,
      },
      {
        name: "English",
        key: "en",
        id: 2,
      },
    ];
    let obj = {
      legalNormsAndStandardMenuId: values.legalNormsAndStandardMenuId,
      legalNormsAndStandardLanguageList: langs.map((l) => {
        return {
          language: l.key,
          content: values[`content_${l.key}`],
        };
      }),
      number:values.number,
      link:values.link,
      date: moment(values.date).format("YYYY-MM-DDTHH:mm")
    };
    if (!this.id) {
      await aictia
        .post(this.mainUrl, obj)
        .then((res) => {
          this.props.notify("", true);
          this.formRef.current.resetFields();
          window.history.back();
        })
        .catch((err) => {
          this.errorGenerator(err.response.data.error);
        });
    } else {
      obj["id"] = this.props.match.params.id;
   
      await aictia
        .put(`${this.mainUrl}/${this.props.match.params.id}`, obj)
        .then((res) => {
          this.props.notify("", true);
          this.formRef.current.resetFields();
          window.history.back();
        })
        .catch((err) => {
          this.errorGenerator(err.response.data.error);
        });
    }
  };

  errorGenerator(error) {
    notify("Xəta baş verdi", false);
  }

  render() {
    let { t, getLangs, notify } = this.props;
    const { TextArea } = Input;
    const lang = [
      {
        key: "az",
        id: 1,
      },
      {
        key: "en",
        id: 2,
      },
    ];
    const langs = [
      {
        name: "Azerbaijan",
        key: "az",
        id: 1,
      },
      {
        name: "English",
        key: "en",
        id: 2,
      },
    ];
    console.log(this.state.fileList);

    return (
      <div>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
              <div className="page-name">
                <PicCenterOutlined className="f-20 mr5-15" />
                <span className="f-20 bold">
                  hüquqi normaları və standartları {this.id ? " redaktə et" : "  əlavə et"}{" "}
                </span>
              </div>
              <Link
                to={{
                  pathname: `/legal-norms`,
                }}
              >
                <Button type={"primary"}>{t("cancel")}</Button>
              </Link>
            </div>
          </Col>
          <Col xs={24}>
            {/*<div className={`${(this.props.match.params.id && !this.state.fileList.length >= 1) ? 'flex animated fadeIn p-2 bg-white all-center' : 'd-none'}`}>*/}
            {/*    <Spin size={"large"} />*/}
            {/*</div>*/}
            <div className={`p-2 animated edit fadeInUp bg-white`}>
              <Form
                ref={this.formRef}
                onFinish={this.saveItem}
                layout="vertical"
              >
                <Row gutter={[8, 8]}>
                  <Col xs={13}>
                    <Form.Item
                      label={"Başlıq"}
                      name={"legalNormsAndStandardMenuId"}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <Select
                        showSearch
                        className={"w-100"}
                        notFoundContent={null}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(e) => {
                          this.setState({ newType: e });
                        }}
                      >
                        {this.state.categories.map((w, i) => {
                          return (
                            <Option key={i} value={w.id}>
                              {w.title}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

             
                <Col xs={24} className="flex">
                <Col xs={5}>
                      <p className="mb-15">Tarix</p>
                      <Form.Item
                        validateTrigger="onChange"
                        name={`date`}
                        // rules={[noWhitespace(t("inputError"))]}
                      >
                        <DatePicker format={["DD-MM-YYYY"]} />
                      </Form.Item>
                    </Col>

                    <Col xs={6}>
                      <p className={"mb-15"}>No</p>

                      <Form.Item
                        validateTrigger="onChange"
                        name={"number"}
                        rules={[noWhitespace(t("inputError"))]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={6}>
                      <p className={"mb-15"}>Link</p>

                      <Form.Item
                        validateTrigger="onChange"
                        name={"link"}
                        rules={[noWhitespace(t("inputError"))]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                </Col>
                 

                  <Col xs={24}>
                    {/*<div className="mb-10">Kontent</div>*/}
                    {langs.map((l) => (
                      <div key={l.id} className="form-lang">
                        <div className="mb-10">{l.name}</div>
                        <Form.Item
                          name={`content_${l.key}`}
                          // validateTrigger="onChange"
                          // getValueFromEvent={(event, editor) => {
                          //     const dat = editor.getData();
                          //     return dat;
                          // }}
                          rules={[noWhitespace(t("inputError"))]}
                        >
                          <JoditEditor
                            ref={this.editor}
                            value={this.state.aboutt[`about_${l.key}`]}
                            tabIndex={1} // tabIndex of textarea
                          />
                        </Form.Item>
                      </div>
                    ))}
                  </Col>

                  <Col xs={24}>
                    <div className={"flex"}>
                      <Button className={"mr-15"} htmlType="submit">
                        {t("save")}
                      </Button>
                      <Link
                        to={{
                          pathname: `/legal-norms`,
                        }}
                      >
                        <Button type={"primary"}>{t("cancel")}</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>

        <Modal
          visible={this.state.previewVisible}
          title={false}
          footer={null}
          onCancel={() => {
            this.setState({ previewVisible: false });
          }}
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={this.state.previewImage}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ langs }) => {
  return {
    langs: langs,
  };
};

const exp = withTranslation()(EditStrategicPartnership);
export default connect(mapStateToProps, { notify })(exp);
