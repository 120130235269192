import React, { Component } from 'react';
import { connect } from "react-redux";
import { notify } from "../../../../redux/actions";
import { noWhitespace } from "../../../../utils/rules";
import { withTranslation } from "react-i18next";
import {
    Col,
    Input,
    Modal,
    Form,
    Row,
    DatePicker,
    Button,
    Switch, TimePicker, Select,
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Upload } from "antd";
import { aictia } from "../../../../const/api";
import JoditEditor from "jodit-react";

import moment from "moment";
const { Option } = Select

class EditNews extends Component {

    formRef = React.createRef();
    editor = React.createRef();
    mainUrl = 'news'
    categoryUrl = 'newscategory'
    mediaUrl = 'MediaAgency'
    id = this.props.match.params.id
    constructor(props) {
        super(props);
    }

    state = {
        aboutt: {},
        categories: [],
        mediaAgency:[],
        isActive: false,
        main: false,
        file: null,
        cover: null,
        fileList: [],
        coverList: [],
        image:[],
        coverImage:{},
        previewImage: "",
        previewCover: "",
        previewVisible: false,
        previewCoverVisible: false,
        newsList:[],
        coverTrigger:false,
        trigger:false,
        newType:0
    };

    onChange = ({ fileList: newFileList }) => {
        this.setState({ fileList: newFileList })
        if (newFileList.length <= 0) {
            this.setState({ file: null })
        }
    };
    onCoverChange = ({ fileList: newFileList }) => {
        this.setState({ coverList: newFileList })
        if (newFileList.length <= 0) {
            this.setState({ cover: null })
        }
    };

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
    getCoverBase64 = (coverFile) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(coverFile);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
    handlePreview = async (file) => {
        console.log(file);
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true
        })
        console.log( this.state.previewImage);
        console.log(this.state.previewVisible);
    };
    handleCoverPreview = async (coverFile) => {
        if (!coverFile.url && !coverFile.preview) {
            coverFile.preview = await this.getCoverBase64(coverFile.originFileObj);
        }
        this.setState({
            previewCover: coverFile.url || coverFile.preview,
            previewCoverVisible: true
        })
        console.log( this.state.previewCover);
        console.log(this.state.previewCoverVisible);
    };
    setUploadFile = ({ onSuccess, onError, file }) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("_file", file, filename);
        console.log();
        aictia
            .post('UploadFile', form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                console.log(res.data);
                this.setState({ file: res.data.url })
                this.setState({image:[...this.state.image,res.data]})
                this.setState({trigger: true})
                onSuccess(null, file);
            })
            .catch((err) => {
                console.log(err);
                this.props.notify("Icaze verilmir", true);
            });
    };

    setCoverUploadFile = ({ onSuccess, onError, file }) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("_file", file, filename);
        console.log(file);
        aictia
            .post('UploadFile', form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                console.log(res.data);
                this.setState({ cover: res.data.url })
                this.setState({coverImage:res.data})
                this.setState({coverTrigger: true})
                onSuccess(null, file);
            })
            .catch((err) => {
                console.log(err);
                this.props.notify("Icaze verilmir", true);
            });
    };

    componentDidMount() {
        if (this.id) {
            this.formRef.current.resetFields()
            let obj = {};
            let datas = {}
            const getPost = async () => {
                await aictia.get(this.mainUrl + '/' + this.id).then((res) => {
                    console.log(res.data);
                    let arr = [...this.state.fileList];
                    let coverArr = [...this.state.coverList];
                    this.setState({ file: res.data.image });
                    this.setState({ cover: res.data.image });
                    coverArr.push({
                        uid: res.data.image,
                        url: res.data.image?.url,
                    });
                    
                    res.data.news_ImageList.forEach((d) => {
                        arr.push({
                            uid: d,
                            url: d.url,
                        });
                    });
                   console.log(coverArr);
                   console.log(arr);
                    obj[`date`] = moment(res.data.publishDate)
                    this.setState({
                        fileList: arr,
                        coverList: coverArr,
                        isActive: res.data.hideShowStatus,
                        main:res.data.showOnMainPage
                    });
                    console.log(this.state.fileList);
                    res.data.news_LanguageList.forEach((d) => {
                        obj[`title_${d.language}`] = d.title;
                        obj[`body_${d.language}`] = d.content;
                        datas[`body_${d.language}`] = d.content;
                    });
                    obj['news_category_id'] = res.data.newsCategoryId
                    obj['media_agency_id'] = res?.data?.mediaAgencyId ? res?.data?.mediaAgencyId : null
                    obj['type'] = res.data.type
                    this.setState({newType:res.data.type})
                    obj['video'] = res.data.videoURL
                    this.setState({
                        aboutt: datas,
                    });
                    if (this.formRef.current !== null) {
                        this.formRef.current.setFieldsValue(obj);
                    }
                });
         console.log(obj);
            };
            getPost();
            const getNewsList = async () => {
                 await aictia.get(this.mainUrl)
                 .then(
                  (res) => {
                      this.setState({
                        newsList:res.data
                      })
                  }
                 )
            }
            getNewsList()
        }
        const getCategories = async () => {
            await aictia.get(this.categoryUrl, { params: { per_page: 4000 } }).then((res) => {
                this.setState({
                    categories:
                        res.data.map((s) => {
                            return {
                                ...s
                            }
                        })
                });
            });
        };
        getCategories();
        const getMedia = async () => {
            await aictia.get(this.mediaUrl, { params: { per_page: 4000 } }).then((res) => {
                console.log(res.data);
                this.setState({
                    mediaAgency:
                        res.data.map((s) => {
                            return {
                                ...s
                            }
                        })
                });
                console.log(this.state.mediaAgency);
            });
        };
        getMedia();
        console.log(this.state.mediaAgency);
    };
    saveItem = async (values) => {
           const langs = [
               {
                  name:'Azerbaijan',
                  key:'az',
                  id:1
               } , 
               {
                  name:'English',
                  key:'en',
                  id:2
               }
              ];
              console.log(moment(values.date).format("YYYY-MM-DDThh:mm"));
              console.log(obj);
        let obj = {
            PublishDate: moment(values.date).format("YYYY-MM-DDTHH:mm"),
            NewsCategoryId: values.news_category_id,
            MediaAgencyId: values?.media_agency_id ? values?.media_agency_id : null,
            Type:this.state.newType,
            HideShowStatus: this.state.isActive,
            News_LanguageList: langs.map((l) => {
                return {
                    Language: l.key,
                    Title: values[`title_${l.key}`],
                    Content: values[`body_${l.key}`],
                };
            }),
        };
        console.log(obj);
        if(this.state.newType === 1){
            obj['Image'] = this.state.coverImage
            obj['News_ImageList'] = this.state.image
        }
        if(this.state.newType === 2){
            obj['VideoURL'] = values.video
        }
        if (!this.id) {
            await aictia
                .post(this.mainUrl, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    this.errorGenerator(err.response.data.error)
                });
        } else {
            console.log(this.state.image);
            console.log(this.state.coverList[0].uid);
            obj["id"] = this.props.match.params.id;
            obj["hideShowStatus"] = this.state.isActive;
            obj["type"] = this.state.newType;
            //obj["news_ImageList"] = this.state.trigger ? this.state.image : this.state.fileList[0].uid
            obj["image"] = this.state.coverTrigger ? this.state.coverImage : this.state.coverList[0].uid
            if (this.state.trigger) {
                obj["news_ImageList"] = this.state.image.map((l) => {
                    console.log(l);
                    return {
                        id: l.id,
                        url: l.url,
                    };
                });
            }else{
                obj["news_ImageList"] = this.state.fileList.map((l) => {
                    console.log(l);
                    return {
                        id: l.uid.id,
                        url: l.uid.url,
                    };
                });
            }
            console.log(obj);
            await aictia
                .put(`${this.mainUrl}/${this.props.match.params.id}`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    this.errorGenerator(err.response.data.error)
                });
                await aictia
                    .put(`News/ShowOnMainPage/${this.props.match.params.id}`, {id:this.props.match.params.id, ShowOnMainPage: this.state.main})
                    .then((res) => {
                         console.log('alindi');
                    })
                    .catch((err) => {
                        this.errorGenerator(err.response.data.error)
                    });
        }
    };

    
 

    errorGenerator(error) {
        notify('Xəta baş verdi', false);
    }
   
    render() {
        let { t, getLangs, notify } = this.props;
        const { TextArea } = Input;
        console.log(this.state.categories);
        const type = [{id:1,name:'Foto'},{id:2,name:'Video'}]
        const lang = [
         {
            key:'az',
            id:1
         } , 
         {
            key:'en',
            id:2
         }
        ];
        console.log(this.state.image);
        const langs = [
            {
               name:'Azerbaijan',
               key:'az',
               id:1
            } , 
            {
               name:'English',
               key:'en',
               id:2
            }
           ];
           console.log(this.state.newType);
        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">Xəbər{this.id ? 'i redaktə et' : '  əlavə et'} </span>
                            </div>
                            <Link
                                to={{
                                    pathname: `/news`,
                                }}
                            >
                                <Button type={"primary"}>{t("cancel")}</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24}>
                        {/*<div className={`${(this.props.match.params.id && !this.state.fileList.length >= 1) ? 'flex animated fadeIn p-2 bg-white all-center' : 'd-none'}`}>*/}
                        {/*    <Spin size={"large"} />*/}
                        {/*</div>*/}
                        <div className={`p-2 animated edit fadeInUp bg-white`}>
                            <Form ref={this.formRef} onFinish={this.saveItem} layout="vertical">
                                <Row gutter={[8, 8]}>
                                    <Col xs={24}>
                                    <Form.Item
                                            label={'Seçim'}
                                            name={"type"}
                                            validateTrigger="onChange"
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <Select
                                                showSearch
                                                className={'w-100'}
                                                notFoundContent={null}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                                onChange={(e)=>{this.setState({newType:e})}}
                                            >
                                                {
                                                    type.map((w, i) => {
                                                        return (
                                                            <Option key={i} value={w.id}>
                                                                {w.name}
                                                            </Option>
                                                        );
                                                      
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {
                                        this.state.newType == 1 
                                    &&
                                    <>
                                         <Col xs={12}>
                                    <p className={"mb-15"}>Cover şəkli</p>
                                        <Form.Item
                                            validateTrigger="onChange"
                                            name={`photo`}
                                            required
                                        >
                                            {/*<ImgCrop*/}
                                            {/*    className={"w-100"}*/}
                                            {/*    rotate*/}
                                            {/*    aspect={1024 / 576}*/}
                                            {/*    grid*/}
                                            {/*>*/}
                                            <Upload
                                                accept=".png, .jpg , .jpeg, .svg"
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture-card"
                                                fileList={this.state.coverList}
                                                onChange={this.onCoverChange}
                                                onPreview={this.handleCoverPreview}
                                                customRequest={this.setCoverUploadFile}
                                                beforeUpload={null}
                                            >
                                                {this.state.coverList.length < 1 && "+ Yüklə"}
                                            </Upload>
                                            <p className="f-10">750 x 500 px </p>
                                            {/*</ImgCrop>*/}
                                        </Form.Item>
                                    </Col>
                                    <Col xs={12}>
                                    <p className={"mb-15"}>Şəkillər</p>
                                        <Form.Item
                                            validateTrigger="onChange"
                                            name={`photo`}
                                            required
                                        >
                                            {/*<ImgCrop*/}
                                            {/*    className={"w-100"}*/}
                                            {/*    rotate*/}
                                            {/*    aspect={1024 / 576}*/}
                                            {/*    grid*/}
                                            {/*>*/}
                                            <Upload
                                                accept=".png, .jpg , .jpeg, .svg"
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture-card"
                                                fileList={this.state.fileList}
                                                onChange={this.onChange}
                                                onPreview={this.handlePreview}
                                                customRequest={this.setUploadFile}
                                                beforeUpload={null}
                                                multiple
                                            >
                                                {this.state.fileList.length < 1 && "+ Yüklə"}
                                            </Upload>
                                            <p className="f-10">750 x 500 px </p>
                                            {/*</ImgCrop>*/}
                                        </Form.Item>
                                    </Col>
                                    </>
    }
                                   { this.state.newType == 2
                                    &&
                                    <>
                                     <Col md={12} sm={12} xs={24}>
                                        <p className={"mb-15"}>Video link</p>
                                            <div className="form-lang">
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={`video`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input className="w-100" />
                                                </Form.Item>
                                            </div>
                                    </Col>
                                    </>
                                    }
                                    <Col md={12} sm={12} xs={24}>
                                        <p className={"mb-15"}>Ad</p>
                                        {lang.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={`title_${l.key}`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input className="w-100" />
                                                </Form.Item>
                                                <span className="input-lang ">{l.key}</span>
                                            </div>
                                        ))}
                                    </Col>
                                    <Col md={12} sm={12} xs={24}>
                                       <Form.Item
                                            label={'Xəbər kateqoriyası'}
                                            name={"news_category_id"}
                                            validateTrigger="onChange"
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <Select
                                                showSearch
                                                className={'w-100'}
                                                notFoundContent={null}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {
                                                    this.state.categories.map((w, i) => {
                                                        return (
                                                            <Option key={i} value={w.id}>
                                                                {w.name}
                                                            </Option>
                                                        );
                                                      
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label={'Media Agentliyi'}
                                            name={"media_agency_id"}
                                            validateTrigger="onChange"
                                        >
                                            <Select
                                                showSearch
                                                className={'w-100'}
                                                notFoundContent={null}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                filterSort={(optionA, optionB) =>
                                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                                }
                                            >
                                                {
                                                    this.state.mediaAgency.map((w, i) => {
                                                        return (
                                                            <Option key={i} value={w.id}>
                                                                {w.name}
                                                            </Option>
                                                        );
                                                      
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                        </Col>

                                    <Col xs={24}>
                                            <div className="flex mb-15">
                                            <Switch
                                                className={"mr-15"}
                                                checked={this.state.isActive}
                                                onChange={() => {
                                                    this.setState({ isActive: !this.state.isActive });
                                                }}
                                            />
                                            <span className="mr-15">Status:</span>
                                            <span className={this.state.isActive ? "green" : "red"}>
                                                {this.state.isActive ? "Aktiv" : "Deaktiv"}
                                            </span>
                                        </div>
                                        <p className={"mb-15"}>Tarix və saat</p>
                                        <div className="flex">
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`date`}
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <DatePicker format={['DD-MM-YYYY']} />
                                            </Form.Item>
                                            <Form.Item
                                                validateTrigger="onChange"
                                                name={`date`}
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <TimePicker   format={'HH:mm'} />
                                            </Form.Item>
                                        </div>

                                    </Col>



                                    <Col xs={24}>
                                        {/*<div className="mb-10">Kontent</div>*/}
                                        {langs.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <div className="mb-10">
                                                    {l.name}
                                                </div>
                                                <Form.Item
                                                    name={`body_${l.key}`}
                                                    validateTrigger="onChange"
                                                    // getValueFromEvent={(event, editor) => {
                                                    //     const dat = editor.getData();
                                                    //     return dat;
                                                    // }}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                   
                                                    <JoditEditor
                                                        ref={this.editor}
                                                        value={this.state.aboutt[`about_${l.key}`]}
                                                        tabIndex={1} // tabIndex of textarea
                                                    />
                                                </Form.Item>
                                            </div>
                                        ))}
                                    </Col>
                                    <Col xs={24}>
                                        <div className={"flex"}>
                                            <Button className={"mr-15"} htmlType="submit">
                                                {t("save")}
                                            </Button>
                                            <Link
                                                to={{
                                                    pathname: `/news`,
                                                    state: {
                                                        locales: null,
                                                    },
                                                }}
                                            >
                                                <Button type={"primary"}>{t("cancel")}</Button>
                                            </Link>
                                        </div>

                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>

                <Modal
                    visible={this.state.previewVisible}
                    title={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({ previewVisible: false })
                    }}
                >
                    <img alt="example" style={{ width: "100%" }} src={this.state.previewImage} />
                </Modal>
            </div>
        );
    }
}



const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditNews);
export default connect(mapStateToProps, { notify })(exp);







