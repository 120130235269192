import React from "react";
import { aictia } from "../../../const/api";
import { useParams, Link } from "react-router-dom";
import {
    Col,
    Row,
    Button
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";

function StartupDetail() {
    const id = useParams().id;
    const mainUrl = "Startup";
    const [data, setData] = React.useState()
    const getData = async () => {
        try {
            const res = await aictia.get(mainUrl);
            console.log(res);
            const arr = res.data.filter((elem) => elem.id === Number(id));
            console.log(arr);
            setData(arr);

        } catch (error) {
            console.log(error);
        }
    };

    React.useEffect(() => {
        getData();
    }, []);
    console.log(data?.[0]);

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <PicCenterOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Ətraflı</span>
                        </div>
                        <Link
                            to={{
                                pathname: `/startup`
                            }}
                        >
                            <Button type={"primary"}>Çıxış</Button>
                        </Link>
                    </div>
                </Col>
            </Row>

            <div >
            <div className='about'>
                <div className='flex flex-between'>
                        <div className="flex dir-column gap-2 mb-10 ">
                            <p className='text-dark f-17 flex gap-2'><span className="font-medium">Büdcə: </span> {data?.[0].budget} AZN</p>
                            <p className='text-dark f-17 flex gap-2'><span className="font-medium">Tamamlanma: </span> {data?.[0].completion} %</p>
                            <p className='text-dark f-17 flex gap-2'><span className="font-medium">Müəllif: </span> {data?.[0].author}</p>
                        </div>
                        <div className='person-img relative'>
            <img src={data?.[0].image.url} alt="" className="image" />
            </div>
                        </div>
                <h2 className='f-22 text-dark mb-10'>Ümumi məlumat</h2>
                <div className='text-dark'>
                <span className='desc'
                  dangerouslySetInnerHTML={{
                    __html: data?.[0].about,
                  }} />
                </div>
                <h2 className='f-22 text-dark mb-10 mt-10'>İnvestorlar</h2>
                {
                    data?.[0]?.investments?.map((elem, index) => (
                        <div className='text-dark'>
                            {/* <span className='desc'
                                dangerouslySetInnerHTML={{
                                    __html: elem.investor,
                                }} /> */}
                        </div>
                    ))
                }
            </div>
           
        </div>

        </>
    );
}

export default StartupDetail;
