import React, { Component } from 'react';
import { connect } from "react-redux";
import { notify } from "../../../redux/actions";
import {noWhitespace } from "../../../utils/rules";
import { withTranslation } from "react-i18next";
import {
    Col,
    Input,
    Modal,
    Form,
    Row,
    Button
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Upload } from "antd";
import { aictia } from "../../../const/api";
import JoditEditor from "jodit-react";

class EditAdvantages extends Component {

    formRef = React.createRef();
    editor = React.createRef();
    mainUrl = 'OurAdvantage'
    id = this.props.match.params.id
    constructor(props) {
        super(props);
    }

    state = {
        aboutt: {},
        file: null,
        fileList: [],
        icon:{},
        previewImage: "",
        previewVisible: false,
        trigger:false
    };

    onChange = ({ fileList: newFileList }) => {
        this.setState({ fileList: newFileList })
        if (newFileList.length <= 0) {
            this.setState({ file: null })
        }
    };

    getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true
        })
    };

    setUploadFile = ({ onSuccess, file }) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("_file", file, filename);
        aictia
            .post('UploadFile', form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                this.setState({ file: res.data.url })
                this.setState({image:res.data})
                this.setState({trigger: true})
                onSuccess(null, file);
            })
            .catch((err) => {
                this.props.notify("Icaze verilmir", true);
            });
    };


    componentDidMount() {
        if (this.id) {
            this.formRef.current.resetFields()
            let obj = {};
            let datas = {}
            const getPost = async () => {
                await aictia.get(this.mainUrl + '/' + this.id).then((res) => {
                    let arr = [...this.state.fileList];
                    this.setState({ file: res.data.icon });
                    arr.push({
                        uid: res.data.icon,
                        url: res.data.icon.url,
                    });
                    this.setState({
                        fileList: arr
                    });
                    res.data.ourAdvantageLanguages.forEach((d) => {
                        obj[`title_${d.language}`] = d.title;
                        obj[`body_${d.language}`] = d.content;
                        datas[`body_${d.language}`] = d.content;
                    });
                    this.setState({
                        aboutt: datas,
                    });
                    if (this.formRef.current !== null) {
                        this.formRef.current.setFieldsValue(obj);
                    }
                });
    
            };
            getPost();
        }
    };
    saveItem = async (values) => {
           const langs = [
               {
                  name:'Azerbaijan',
                  key:'az',
                  id:1
               } , 
               {
                  name:'English',
                  key:'en',
                  id:2
               }
              ];
        let obj = {
            Icon: this.state.image,
            OurAdvantageLanguages: langs.map((l) => {
                return {
                    language: l.key,
                    title: values[`title_${l.key}`],
                    content: values[`body_${l.key}`],
                };
            }),
        };
        if (!this.id) {
        
            await aictia
                .post(this.mainUrl, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    this.errorGenerator(err.response.data.error)
                });
        } else {
            obj["id"] = this.props.match.params.id;
            obj["icon"] = this.state.trigger ? this.state.image : this.state.fileList[0].uid
            await aictia
                .put(`${this.mainUrl}/${this.props.match.params.id}`, obj)
                .then((res) => {
                    this.props.notify("", true);
                    this.formRef.current.resetFields();
                    window.history.back();
                })
                .catch((err) => {
                    this.errorGenerator(err.response.data.error)
                });
        }
    };




    errorGenerator(error) {
        notify('Xəta baş verdi', false);
    }
   
    render() {
        let { t } = this.props;
        const lang = [
         {
            key:'az',
            id:1
         } , 
         {
            key:'en',
            id:2
         }
        ];
        const langs = [
            {
               name:'Azerbaijan',
               key:'az',
               id:1
            } , 
            {
               name:'English',
               key:'en',
               id:2
            }
           ];
        return (
            <div>
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15" />
                                <span className="f-20 bold">Üstünlüklərimiz {this.id ? 'i redaktə et' : '  əlavə et'} </span>
                            </div>
                            <Link
                                to={{
                                    pathname: `/advantages`,
                                }}
                            >
                                <Button type={"primary"}>{t("cancel")}</Button>
                            </Link>
                        </div>
                    </Col>
                    <Col xs={24}>
                        {/*<div className={`${(this.props.match.params.id && !this.state.fileList.length >= 1) ? 'flex animated fadeIn p-2 bg-white all-center' : 'd-none'}`}>*/}
                        {/*    <Spin size={"large"} />*/}
                        {/*</div>*/}
                        <div className={`p-2 animated edit fadeInUp bg-white`}>
                            <Form ref={this.formRef} onFinish={this.saveItem} layout="vertical">
                                <Row gutter={[8, 8]}>
                                    <Col xs={12}>
                                        <Form.Item
                                            validateTrigger="onChange"
                                            name={`photo`}
                                            required
                                        >
                                            <Upload
                                                accept=".png, .jpg , .jpeg, .svg"
                                                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                listType="picture-card"
                                                fileList={this.state.fileList}
                                                onChange={this.onChange}
                                                onPreview={this.handlePreview}
                                                customRequest={this.setUploadFile}
                                                beforeUpload={null}
                                            >
                                                {this.state.fileList.length < 1 && "+ Yüklə"}
                                            </Upload>
                                            <p className="f-10">64 x 64 px </p>
                                        </Form.Item>
                                    </Col>

                                    <Col md={12} sm={12} xs={24}>
                                        <p className={"mb-15"}>Ad</p>
                                        {lang.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    name={`title_${l.key}`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input className="w-100" />
                                                </Form.Item>
                                                <span className="input-lang ">{l.key}</span>
                                            </div>
                                        ))}

                                    </Col>
                                    <Col xs={24}>
                                        {langs.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <div className="mb-10">
                                                    {l.name}
                                                </div>
                                                <Form.Item
                                                    name={`body_${l.key}`}
                                                    validateTrigger="onChange"
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <JoditEditor
                                                        ref={this.editor}
                                                        value={this.state.aboutt[`about_${l.key}`]}
                                                        tabIndex={1} // tabIndex of textarea
                                                    />
                                                </Form.Item>
                                            </div>
                                        ))}
                                    </Col>
                                    <Col xs={24}>
                                        <div className={"flex"}>
                                            <Button className={"mr-15"} htmlType="submit">
                                                {t("save")}
                                            </Button>
                                            <Link
                                                to={{
                                                    pathname: `/philosophy`,
                                                }}
                                            >
                                                <Button type={"primary"}>{t("cancel")}</Button>
                                            </Link>
                                        </div>

                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>

                <Modal
                    visible={this.state.previewVisible}
                    title={false}
                    footer={null}
                    onCancel={() => {
                        this.setState({ previewVisible: false })
                    }}
                >
                    <img alt="example" style={{ width: "100%" }} src={this.state.previewImage} />
                </Modal>
            </div>
        );
    }
}



const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

const exp = withTranslation()(EditAdvantages);
export default connect(mapStateToProps, { notify })(exp);

