import React from "react";
import Loader from "./Elements/Loader";
import { connect } from "react-redux";
import TopMenu from "./Layout/TopMenu/TopMenu";
import { Route, Switch, Redirect } from "react-router-dom";
import Login from "./Layout/Login/Login";
import NewsCategory from "./Pages/Admin/NewsCategory/NewsCategory";
import News from "./Pages/Multimedia/News/News";
import ContactInfo from "./Pages/Contact/Contact";
import History from "./Pages/history/history";
import { getUserData, logOut } from "./../redux/actions";
import MenuList from "./Elements/MenuList";
import EditPhilosophy from "./Pages/Philosophy/Edit";
import BottomMenu from "./Elements/BottomMenu";
import Philosophy from "./Pages/Philosophy";
import HonoraryPersons from "./Pages/Honorary-Persons";
import Message from "./Pages/Message";
import EditNews from "./Pages/Multimedia/News/EditNews";
import EditHonoraryPersons from "./Pages/Honorary-Persons/Edit";
import EditContact from "./Pages/Contact/EditContact";
import Advantages from "./Pages/Advantages";
import EditAdvantages from "./Pages/Advantages/Edit";
import Services from "./Pages/Services";
import EditServices from "./Pages/Services/Edit";
import Memorandums from "./Pages/Memorandums";
import EditMemorandums from "./Pages/Memorandums/edit";
import PrivacyPolicy from "./Pages/Privacy-Policy";
import StrategicPartnership from "./Pages/StrategicPartnership";
import EditStrategicPartnership from "./Pages/StrategicPartnership/edit";
import MembersIndividual from "./Pages/Members-individual";
import Detail from "./Pages/registration/detail";
import Startup from "./Pages/Startup";
import MediaAgency from "./Pages/Admin/MediaAgency";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { Button, Drawer, notification, Tooltip, Badge, Avatar } from "antd";


import logo from "../assets/img/logo.svg";

import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  SmileOutlined,
  FrownOutlined,
  BellFilled,
  HomeOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { withTranslation } from "react-i18next";
import history from "../const/history";
import Notfound from "./Elements/404";
import Individual from "./Pages/registration/individual";
import EditPacket from "./Pages/registration/editPacket";
import TypeOfActivity from "./Pages/Admin/TypeOfActivity/TypeOfActivity";
import Corporative from "./Pages/corporativeRegistration/corporative";
import EditPackett from "./Pages/corporativeRegistration/edit";
import Detaill from "./Pages/corporativeRegistration/detail";
import ReasonOfReject from "./Pages/Admin/ReasonOfReject";
import EthicalRules from "./Pages/EthicalRules/EthicalRules";
import Users from "./Pages/Admin/Users";
import Home from "./Layout/Home/Home";
import Branch from "./Pages/Admin/Branch";
import StartupDetail from "./Pages/Startup/detail";
import Managment from "./Pages/Managment/managment";
import EditManagment from "./Pages/Managment/managmentedit";
import Frame from "../assets/img/Frameee.jpeg";
import LegalNorms from "./Pages/LegalNorms/legalNorms";
import LegalNormsEdit from "./Pages/LegalNorms/legalNormsEdit";

const { Content, Sider } = Layout;

class App extends React.Component {
  state = {
    collapsed: false,
    web: true,
    ismap: false,
    stocks: null,
    isWhite: true,
  };
  toggleButtons = () => {
    const className = `flex sider-btn ${
      this.state.collapsed ? "all-center" : "flex-between open"
    }`;
    return (
      <div className={className}>
        {!this.state.collapsed ? (
          <>
            <div
            className="close-btn"
              onClick={() =>
                this.setState({
                  collapsed: true,
                })
              }
            >
              <CloseOutlined />
            </div>

            <Link to={`/reports`}>
              <Tooltip placement="bottomRight" title={"Bildirişlər"}>
                <Badge>
                  <Avatar
                    className="circle-notification"
                    size={30}
                    icon={<BellFilled />}
                  />
                </Badge>
              </Tooltip>
            </Link>
            <Link to={`/`}>
              <Tooltip placement="bottomRight" title={"Ana səhifə"}>
                <Badge>
                  <Avatar
                    className="circle-notification"
                    size={30}
                    icon={<HomeOutlined />}
                  />
                </Badge>
              </Tooltip>
            </Link>
          </>
        ) : null}

        {/* <Button type="primary" onClick={this.onCollapse}>
          {this.state.collapsed ? <MenuUnfoldOutlined /> : "ss"}
        </Button> */}

        {
          this.state.collapsed ? (
            <Button type="primary" onClick={this.onCollapse}>
               <MenuUnfoldOutlined />
          </Button>
          )
          :   <img src={Frame} alt="" />
        }

      </div>
    );
  };

  onCollapse = () => {
    this.setState({ collapsed: !this.state.collapsed });
    this.setState({ web: window.innerWidth > 1200 });
  };

  componentDidMount() {
    this.props.getUserData();
    this.setState({ web: window.innerWidth > 1200 });
    window.addEventListener("resize", () => {
      this.setState({
        web: window.innerWidth > 1200,
        // collapsed: window.innerWidth < 1200,
      });
    });
    this.setState({
      ismap: window.location.pathname === "/",
      isWhite: window.location.pathname === "/",
    });
    history.listen((location) => {
      this.setState({
        ismap: location.pathname === "/",
        isWhite: location.pathname === "/",
      });
    });
  }

  componentDidUpdate(prevProps) {
    const prev = prevProps.notification;
    const curr = this.props.notification;
    if (prev.notify !== curr.notify) {
      let desc = curr.description;
      console.log(curr.description);
      console.log(desc);
      notification.info({
        message: curr.isHappy
          ? this.props.t("successMessage")
          : this.props.t("errMessage"),
        description: desc.data ? desc.data : desc.length ? desc : null,
        icon: curr.isHappy ? <SmileOutlined /> : <FrownOutlined />,
      });
    }
  }

  render() {
    const { t } = this.props;

    return (
      <>
        {this.props.isLoading ? <Loader /> : null}
        {this.props.isLoggedIn ? (
          <>
            <div id="page">
              <Layout className="letside">
                {this.state.web ? (
                  <Sider
                    className="side-menu"
                    style={{ backgroundColor: "white" }}
                    collapsed={this.state.collapsed}
                    collapsedWidth={80}
                    onCollapse={this.onCollapse}
                    width={300}
                  >
                    {this.toggleButtons()}
                    <MenuList collapsed={this.state.collapsed} />
                    <BottomMenu />
                  </Sider>
                ) : (
                  <Drawer
                    className="drawer"
                    width={320}
                    title={this.toggleButtons()}
                    placement="left"
                    closable={false}
                    onClose={this.onCollapse}
                    visible={!this.state.collapsed}
                    key="key"
                  >
                    <MenuList collapsed={this.state.collapsed} />
                    <BottomMenu />
                  </Drawer>
                )}
                <Layout
                  className={
                    this.state.collapsed
                      ? "collapsedRight"
                      : "nonCollapsedRight"
                  }
                >
                  {/* <TopMenu
                    toggleDrawer={this.onCollapse}
                    showDrawerButton={!this.state.web}
                    collapsed={this.state.collapsed}
                    toggleButtons={this.toggleButtons}
                  /> */}
                  <Content>
                    <div
                      className={`page-routes ${
                        this.state.ismap ? "isMap" : ""
                      }`}
                    >
                      <Switch>
                        <Route
                          exact
                          path={`/news/categories`}
                          component={NewsCategory}
                        />
                        <Route exact path={`/news`} component={News} />
                        <Route
                          exact
                          path={`/news/edit/:id`}
                          component={EditNews}
                        />
                        <Route
                          exact
                          path={`/news/create`}
                          component={EditNews}
                        />
                        <Route exact path={`/history`} component={History} />
                        <Route
                          exact
                          path={`/individual-registration`}
                          component={Individual}
                        />
                        <Route
                          exact
                          path={`/packet/create`}
                          component={EditPacket}
                        />
                        <Route
                          exact
                          path={`/packet/edit/:id`}
                          component={EditPacket}
                        />
                        <Route
                          exact
                          path={`/packet/detail/:id`}
                          component={Detail}
                        />
                        <Route
                          exact
                          path={`/ethical-rules`}
                          component={EthicalRules}
                        />
                        <Route
                          exact
                          path={`/contact`}
                          component={ContactInfo}
                        />
                        <Route
                          exact
                          path={`/contact/edit/:id`}
                          component={EditContact}
                        />
                        <Route
                          exact
                          path={`/contact/create`}
                          component={EditContact}
                        />
                        <Route
                          exact
                          path={`/philosophy`}
                          component={Philosophy}
                        />
                        <Route
                          exact
                          path={`/philosophy/create`}
                          component={EditPhilosophy}
                        />
                        <Route
                          exact
                          path={`/philosophy/edit/:id`}
                          component={EditPhilosophy}
                        />
                        <Route
                          exact
                          path={`/advantages`}
                          component={Advantages}
                        />
                        <Route
                          exact
                          path={`/privacy-policy`}
                          component={PrivacyPolicy}
                        />
                        <Route exact path={`/services`} component={Services} />
                        <Route
                          exact
                          path={`/honorary-person`}
                          component={HonoraryPersons}
                        />
                        <Route
                          exact
                          path={`/honorary-person/create`}
                          component={EditHonoraryPersons}
                        />
                        <Route
                          exact
                          path={`/honorary-person/edit/:id`}
                          component={EditHonoraryPersons}
                        />
                        <Route
                          exact
                          path={`/strategic-partnership`}
                          component={StrategicPartnership}
                        />
                        <Route
                          exact
                          path={`/strategic-partnership/create`}
                          component={EditStrategicPartnership}
                        />
                        <Route
                          exact
                          path={`/strategic-partnership/edit/:id`}
                          component={EditStrategicPartnership}
                        />
                        <Route
                          exact
                          path={`/advantages/create`}
                          component={EditAdvantages}
                        />
                        <Route
                          exact
                          path={`/advantages/edit/:id`}
                          component={EditAdvantages}
                        />
                        <Route
                          exact
                          path={`/services/create`}
                          component={EditServices}
                        />
                        <Route
                          exact
                          path={`/services/edit/:id`}
                          component={EditServices}
                        />
                        <Route
                          exact
                          path={`/memorandums/create`}
                          component={EditMemorandums}
                        />
                        <Route
                          exact
                          path={`/memorandums/edit/:id`}
                          component={EditMemorandums}
                        />
                        <Route
                          exact
                          path={`/memorandums`}
                          component={Memorandums}
                        />
                        <Route exact path={`/message`} component={Message} />

                        <Route
                          exact
                          path={`/corporative-registration`}
                          component={Corporative}
                        />
                        <Route
                          exact
                          path={`/corporative-packet/create`}
                          component={EditPackett}
                        />
                        <Route
                          exact
                          path={`/corporative-packet/edit/:id`}
                          component={EditPackett}
                        />
                        <Route
                          exact
                          path={`/corporative-packet/detail/:id`}
                          component={Detaill}
                        />
                        <Route
                          exact
                          path={`/members/individual-members`}
                          component={MembersIndividual}
                        />

                        <Route
                          exact
                          path={`/activity/type`}
                          component={TypeOfActivity}
                        />
                        <Route
                          exact
                          path={`/reasonofreject`}
                          component={ReasonOfReject}
                        />

                        <Route exact path={`/admin/users`} component={Users} />
                        <Route
                          exact
                          path={`/admin/branch`}
                          component={Branch}
                        />
                        <Route exact path={`/startup`} component={Startup} />
                        <Route
                          exact
                          path={`/startup/detail/:id`}
                          component={StartupDetail}
                        />

                        <Route
                          exact
                          path={`/managment`}
                          component={Managment}
                        />
                        <Route
                          exact
                          path={`/managment/edit/:id`}
                          component={EditManagment}
                        />
                        <Route
                          exact
                          path={`/managment/create`}
                          component={EditManagment}
                        />

                        <Route
                          exact
                          path={`/admin/media-agency`}
                          component={MediaAgency}
                        />

                        <Route
                          exact
                          path={`/legal-norms`}
                          component={LegalNorms}
                        />
                        <Route
                          exact
                          path={`/legal-norms/create`}
                          component={LegalNormsEdit}
                        />
                        <Route
                          exact
                          path={`/legal-norms/edit/:id`}
                          component={LegalNormsEdit}
                        />

                        <Route exact path={`/`} component={MembersIndividual} />

                        <Redirect to="/" />
                        <Route path="/">
                          <div className="flex all-center h-100vh">
                            <Notfound />
                          </div>
                        </Route>
                      </Switch>
                    </div>
                  </Content>
                </Layout>
              </Layout>
            </div>
          </>
        ) : (
          <>
            <Switch>
              <Route exact path="/" component={Login} />
              <Route path="/">
                <Notfound />
              </Route>
            </Switch>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ user, loader, notification }) => {
  return {
    isLoggedIn: user.isLoggedIn,
    // isLoggedIn: true,
    isLoading: loader,
    notification,
  };
};

const exp = withTranslation()(App);
export default connect(mapStateToProps, { getUserData })(exp);
