import React, { useState, useEffect } from "react";
import { Col, Row, Button, Card } from "antd";
import {
    PicCenterOutlined,
    MailOutlined,
    PhoneOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import aictia from "../../../const/api";
import { useTranslation } from "react-i18next";
import { notify } from "../../../redux/actions";
import nodata from "../../../assets/img/nodata.png";

function ContactInfo() {
    let mainUrl = 'Contact'
    const [infos, setInfos] = useState({});
    const { t } = useTranslation();
    let [empty, setEmpty] = useState(false);
    const getInfos = () => {
        aictia.get(mainUrl).then((res) => {
            let data = res.data
            if (res.data) {
                setInfos(data)
            }
            else{
                setEmpty(true)
            }
        });
    };
    const deleteContact = async (i) => {
        if (i === 0 || i) {
            await aictia
                .delete(`${mainUrl}/${i}`)
                .then((res) => {
                    setEmpty(true);
                    notify("silindi", true);
                })
                .catch((res) => {
                    notify(res.err, false);
                });
        }
    }
    useEffect(() => {
        getInfos();
    }, [t]);
    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <div className="border  flex-between pag  e-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <PicCenterOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Əlaqə məlumatları</span>
                        </div>
                        <div className="flex flex-wrap gap-2">
                            {
                                !empty && (
                                    <div>
                                    <Link to={`/contact/edit/${infos.id}`}>
                                        {" "}
                                        <Button type={"primary"}>{t("edit")}</Button>
                                    </Link>
                                </div>
                                )
                            }
                            {
                                !empty && (
                                    <div>
                                    <Button type={"primary"} onClick={() => deleteContact(infos.id)}>{t("delete")}</Button>
                                </div>
                                )
                            }
                            <div>
                                <Link to={`/contact/create`}>
                                    {" "}
                                    <Button type={"primary"}>{t("create")}</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Col>
                {
               empty ? (
                        <Col xs={24}>
                            <div className="border all-center pag  e-heading flex p-2 mt-0 bg-white "><img src={nodata} /></div>
                        </Col>
                    )  : (
                        <Col xs={24}>
                            <div className="border  flex-between pag  e-heading contact p-2 mt-0 bg-white">
                            <Col md={8}>
                                <Card  className={"animated fadeInLeft"}>
                                    <div className="mb-20">
                                        <p className={"f-18"}>
                                            <PhoneOutlined /> Telefon
                                        </p>
                                        {
                                            infos?.phoneList?.map((elem) => (
                                                <div className="mb-2">
                                                    <p className={"f-17"}>
                                                        {elem.phone}
                                                    </p>
                                                </div>
                                            ))
                                        }

                                    </div>

                                    <div className="mb-20">
                                        <p className={"f-18"}>
                                            <MailOutlined /> Email
                                        </p>
                                        {
                                            infos?.mailList?.map((elem) => (
                                                <div className="mb-2">
                                                    <p className={"f-17"}>
                                                        {elem.mail}
                                                    </p>
                                                </div>
                                            ))
                                        }

                                    </div>
                                </Card>
                            </Col>
                            <Col md={16}>
                                <Card className={"d-block h-100 animated fadeInRight"}>
                                    <div>
                                        <p className={"f-20"}>{t("Address")}</p>
                                        <p className={"f-17"}>{infos?.adress}</p>
                                    </div>
                                </Card>
                            </Col>
                            </div>
                        </Col>
                    )
                }

            </Row>
        </div>
    );
}

export default ContactInfo;