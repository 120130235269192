import React, { Component } from "react";
import { connect } from "react-redux";
import { notify } from "../../../redux/actions";
import { noWhitespace } from "../../../utils/rules";
import { withTranslation } from "react-i18next";
import { Col, Input, Modal, Form, Row, Button, Select, Checkbox } from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { Upload } from "antd";
import { aictia } from "../../../const/api";
import JoditEditor from "jodit-react";

const { Option } = Select;

class ManagmentEdit extends Component {
  formRef = React.createRef();
  editor = React.createRef();
  mainUrl = "Staff";
  personsUrl = "Account/Persons";
  id = this.props.match.params.id;
  constructor(props) {
    super(props);
  }

  state = {
    aboutt: {},
    file: null,
    fileList: [],
    image: {},
    previewImage: "",
    previewVisible: false,
    trigger: false,
    isCheckedManagement: false,
    isCheckedCentral: false,
    persons: [],
    managementerror: "",
    centralerror: "",
  };

  onChange = ({ fileList: newFileList }) => {
    this.setState({ fileList: newFileList });
    if (newFileList.length <= 0) {
      this.setState({ file: null });
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }
    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  setUploadFile = ({ onSuccess, onError, file }) => {
    let form_data = new FormData();
    console.log(file);
    const filename = Math.random(1, 999999) + Date.now() + file.name;
    form_data.append("_file", file, filename);
    console.log(filename);

    aictia
      .post("UploadFile", form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res);
        this.setState({ file: res.data.url });
        this.setState({ image: res.data });
        this.setState({ trigger: true });
        onSuccess(null, file);
      })
      .catch((err) => {
        console.log(err);
        this.props.notify("Icaze verilmir", true);
      });
  };

  componentDidMount() {
    if (this.id) {
      this.formRef.current.resetFields();
      let obj = {};
      let datas = {};
      const getPost = async () => {
        await aictia.get(this.mainUrl + "/" + this.id).then((res) => {
          let arr = [...this.state.fileList];
          console.log(res);
          this.setState({ file: res.data.image });
          arr.push({
            uid: res.data.image,
            url: res.data.image.url,
          });
          this.setState({
            fileList: arr,
          });

          obj["persons_id"] = res.data.personId;

          this.setState({ isCheckedManagement: res.data.boardOfDirectors });
          this.setState({ isCheckedCentral: res.data.centralStaff });

          res.data.boardOfDirectorsPosts.forEach((d) => {
            obj[`BoardOfDirectors_${d.language}`] = d.post;
          });
          res.data.centralStaffPosts.forEach((d) => {
            obj[`CentralStaff_${d.language}`] = d.post;
          });
          // this.setState({
          //   aboutt: datas,
          // });
          console.log(obj);
          if (this.formRef.current !== null) {
            this.formRef.current.setFieldsValue(obj);
          }
        });
      };
      getPost();
    }
    const getCategories = async () => {
      await aictia
        .get(this.personsUrl, { params: { per_page: 4000 } })
        .then((res) => {
          this.setState({
            persons: res.data.map((s) => {
              return {
                ...s,
              };
            }),
          });
        });
    };
    getCategories();
  }
  saveItem = async (values) => {
    const langs = [
      {
        name: "Azerbaijan",
        key: "az",
        id: 1,
      },
      {
        name: "English",
        key: "en",
        id: 2,
      },
    ];
    let obj = {
      Image: this.state.image,
      PersonId: values.persons_id,
      BoardOfDirectors: this.state.isCheckedManagement,
      CentralStaff: this.state.isCheckedCentral,

      BoardOfDirectorsPosts: langs.map((l) => {
        return {
          Language: l.key,
          Post: values[`BoardOfDirectors_${l.key}`],
        };
      }),
      CentralStaffPosts: langs.map((l) => {
        return {
          Language: l.key,
          Post: values[`CentralStaff_${l.key}`],
        };
      }),
    };

    if( this.state.isCheckedManagement || this.state.isCheckedCentral){
    if (!this.id) {
      await aictia
        .post(this.mainUrl, obj)
        .then((res) => {
          this.props.notify("", true);
          this.formRef.current.resetFields();
          window.history.back();
        })
        .catch((err) => {
          this.errorGenerator(err.response.data.error);
        });
    } else {
      console.log(this.state.trigger);
      obj["id"] = this.props.match.params.id;
      obj["image"] = this.state.trigger
        ? this.state.image
        : this.state.fileList[0].uid;

      await aictia
        .put(`${this.mainUrl}/${this.props.match.params.id}`, obj)
        .then((res) => {
          this.props.notify("", true);
          this.formRef.current.resetFields();
          window.history.back();
        })
        .catch((err) => {
          this.errorGenerator(err.response.data.error);
        });
    }
  }
  };

  errorGenerator(error) {
    notify("Xəta baş verdi", false);
  }

  handleManagment = () => {
    this.setState((prevState) => ({
      isCheckedManagement: !prevState.isCheckedManagement,
    }));
    let obj = {
      BoardOfDirectors_az: "",
      BoardOfDirectors_en: "",
    };
    this.setState({ centralerror: "" });
    if (this.formRef.current !== null) {
      this.formRef.current.setFieldsValue(obj);
    }
  };
  handleManagmentCentral = () => {
    this.setState((prevState) => ({
      isCheckedCentral: !prevState.isCheckedCentral,
    }));
    this.setState({ managementerror: "" });
    let obj = {
      CentralStaff_az: "",
      CentralStaff_en: "",
    };
    if (this.formRef.current !== null) {
      this.formRef.current.setFieldsValue(obj);
    }
  };

  render() {
    let { t, getLangs, notify } = this.props;
    const { TextArea } = Input;
    const lang = [
      {
        key: "az",
        id: 1,
      },
      {
        key: "en",
        id: 2,
      },
    ];
    const langs = [
      {
        name: "Azerbaijan",
        key: "az",
        id: 1,
      },
      {
        name: "English",
        key: "en",
        id: 2,
      },
    ];

    return (
      <div>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
              <div className="page-name">
                <PicCenterOutlined className="f-20 mr5-15" />
                <span className="f-20 bold">
                  {this.id ? "i redaktə et" : "  əlavə et"}{" "}
                </span>
              </div>
              <Link
                to={{
                  pathname: `/managment`,
                  // state: {
                  //   locales: null,
                  //   name: this.props.location.state.name,
                  // },
                }}
              >
                <Button type={"primary"}>{t("cancel")}</Button>
              </Link>
            </div>
          </Col>
          <Col xs={24}>
            {/*<div className={`${(this.props.match.params.id && !this.state.fileList.length >= 1) ? 'flex animated fadeIn p-2 bg-white all-center' : 'd-none'}`}>*/}
            {/*    <Spin size={"large"} />*/}
            {/*</div>*/}
            <div className={`p-2 animated edit fadeInUp bg-white`}>
              <Form
                ref={this.formRef}
                onFinish={this.saveItem}
                layout="vertical"
              >
                <Row gutter={[8, 8]}>
                  <Col xs={12}>
                    <Form.Item
                      validateTrigger="onChange"
                      name={`photo`}
                      required
                    >
                      {/*<ImgCrop*/}
                      {/*    className={"w-100"}*/}
                      {/*    rotate*/}
                      {/*    aspect={1024 / 576}*/}
                      {/*    grid*/}
                      {/*>*/}
                      <Upload
                        accept=".png, .jpg , .jpeg , .svg "
                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                        listType="picture-card"
                        fileList={this.state.fileList}
                        onChange={this.onChange}
                        onPreview={this.handlePreview}
                        customRequest={this.setUploadFile}
                        beforeUpload={null}
                      >
                        {this.state.fileList.length < 1 && "+ Yüklə"}
                      </Upload>
                      <p className="f-10">75 x 75 px </p>
                      {/*</ImgCrop>*/}
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>Şəxslər</p>

                    <Form.Item
                      // label={'Kateqoriyası'}
                      name={"persons_id"}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <Select
                        showSearch
                        className={"w-100"}
                        notFoundContent={null}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {this.state.persons.map((w, i) => {
                          return (
                            <Option key={i} value={w.id}>
                              {w.name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    {/*<div className="mb-10">Kontent</div>*/}

                    <div className="mb-10">
                      <Form.Item
                        name={`management_check`}
                        // validateTrigger="onChange"
                        // getValueFromEvent={(event, editor) => {
                        //     const dat = editor.getData();
                        //     return dat;
                        // }}
                        //   rules={[noWhitespace(t("inputError"))]}
                      >
                        <Checkbox
                          checked={this.state.isCheckedManagement}
                          onChange={this.handleManagment}
                        >
                          İdarəetmə heyəti
                        </Checkbox>
                      </Form.Item>
                      <p className={"mb-15"}>vəzifə</p>

                      {lang.map((l) => (
                        <div key={l.id} className="form-lang">
                          <Form.Item
                            validateTrigger="onChange"
                            name={`BoardOfDirectors_${l.key}`}
                            rules={[
                              this.state.isCheckedManagement &&
                                noWhitespace(t("inputError")),
                            ]}
                          >
                            <Input
                              className="w-100"
                              disabled={!this.state.isCheckedManagement}
                            />
                          </Form.Item>
                          <span className="input-lang ">{l.key}</span>
                        </div>
                      ))}
                    </div>
                    <span className="management-error">
                      {this.state.managementerror}
                    </span>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <div className="mb-10 ml-15">
                      <Form.Item
                        name={`central_check`}
                        // validateTrigger="onChange"
                        // getValueFromEvent={(event, editor) => {
                        //     const dat = editor.getData();
                        //     return dat;
                        // }}
                        //   rules={[noWhitespace(t("inputError"))]}
                      >
                        <Checkbox
                          checked={this.state.isCheckedCentral}
                          onChange={this.handleManagmentCentral}
                        >
                          Mərkəzi İdarəetmə
                        </Checkbox>
                      </Form.Item>
                      <p className={"mb-15"}>vəzifə</p>

                      {lang.map((l) => (
                        <div key={l.id} className="form-lang">
                          <Form.Item
                            validateTrigger="onChange"
                            name={`CentralStaff_${l.key}`}
                            rules={[
                              this.state.isCheckedCentral &&
                                noWhitespace(t("inputError")),
                            ]}
                          >
                            <Input
                              className="w-100"
                              disabled={!this.state.isCheckedCentral}
                            />
                          </Form.Item>
                          <span className="input-lang ">{l.key}</span>
                        </div>
                      ))}
                    </div>
                    <span className="management-error">
                      {this.state.centralerror}
                    </span>
                  </Col>

                  <Col xs={24}>
                    <div className={"flex"}>
                      <Button className={"mr-15"} htmlType="submit">
                        {t("save")}
                      </Button>
                      <Link
                        to={{
                          pathname: `/managment`,
                        
                        }}
                      >
                        <Button type={"primary"}>{t("cancel")}</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>

        <Modal
          visible={this.state.previewVisible}
          title={false}
          footer={null}
          onCancel={() => {
            this.setState({ previewVisible: false });
          }}
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={this.state.previewImage}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ langs }) => {
  return {
    langs: langs,
  };
};

const exp = withTranslation()(ManagmentEdit);
export default connect(mapStateToProps, { notify })(exp);
