import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
    Button,
    Tooltip,
    Menu,
    Row,
    Col,
    Table,
    Spin,
    DatePicker,
    Select,
    Form,
    Tabs
} from "antd";
import { Dropdown,Modal } from 'antd';
import PersonCorpoDetails from "./modalCorpoDetail";
import moment from "moment";
import { convertColumns } from "../../../utils/columnconverter";
import {
    ArrowRightOutlined
} from "@ant-design/icons";
import {
    PicCenterOutlined,
    ClearOutlined
  } from "@ant-design/icons";

import { notify } from "../../../redux/actions";
import { connect } from "react-redux";
import { aictia } from "../../../const/api"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import PersonDetails from "./modalDetail";
import axios from "axios";
// import IndividualMembersComp from "../../membersComp";
const { SubMenu } = Menu;
const { TabPane } = Tabs;
const { Option } = Select;
function IndividualMembers(props) {
    let mainUrl = 'Account/Individual'
    const [individualPostList, setIndividualPostList] = useState([]);
    const [corporativePostList, setCorporativePostList] = useState([]);
    const [packet, setPacket] = useState([]);
    const [spin, setSpin] = useState(false);
    const [reason, setReason] = useState([]);
    const { t } = useTranslation();
    const [country,setCountry] = useState([]);
    const [visiblePPurchase, setVisiblePPurchase] = useState(false);
    const [visibleCorporative, setVisibleCorporative] = useState(false);
    const [id, setId] = useState();
    const [corpoId, setCorpoId] = useState();
    const cols = [
        { key: "tableIndex", value: "#", con: true },
        { key: "fullName", value: t("name"), con: true },
        { key: "specialty", value: "İxtisas", con: true },
        { key: "packet", value: 'Paket', con: true },
        { key: "status", value: 'Status', con: false },
        { key: "gmail", value: 'Gmail', con: true }
    ];

    const approveAccount = async (i) => {
        const obj = { Id: i, "StatusOfAccount": true }
        await aictia.post('Account', obj)
            .then((res) => {
                console.log('alinfi');
                getCorporativeList()
                getIndividualList()
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const rejectAccount = async (i,r) => {
        const obj = { Id: i, "StatusOfAccount": false, "ReasonOfRejectId": r.id}
        await aictia.post('Account', obj)
            .then((res) => {
                console.log('alinfi');
                getCorporativeList()
                getIndividualList()
            })
            .catch((err) => {
                console.log(err);
            });
    }  
    const reasonOfReject = async (i) => {
        await aictia.get(`ReasonOfReject`)
            .then((res) => {
                console.log(res.data);
                setReason(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const individualColumns = [
        {
            title: "#",
            dataIndex: "tableIndex",
            key: "1",
            width: 60,
        },
        {
            title: t("name"),
            dataIndex: "fullName",
            key: "2",
        },
        {
            title: 'İxtisas',
            dataIndex: "specialty",
            key: "3",
        },
        {
            title: 'Paket',
            dataIndex: "packet",
            key: "4",
        },
        {
            title: 'Status',
            dataIndex: "status",
            key: "6",
            render: (i) => {
                if (i === 1) {
                    return <span style={{
                        fontSize: '11px',
                        backgroundColor: '#E49F33',
                    }}
                        className="statusBox" >Gözləmədə</span>;
                }
                if (i === 2) {
                    return <span style={{
                        fontSize: '11px',
                        backgroundColor: "#7FBFA4",
                    }}
                        className="statusBox" >Təsdiq</span>;
                }
                if (i === 3) {
                    return <span style={{
                        fontSize: '11px',
                        backgroundColor: "#D24C41",
                    }}
                        className="statusBox">İmtina</span>;
                }
            }
        },
        {
            title: 'Email',
            dataIndex: "gmail",
            key: "7",

        },
        {
            title: "",
            dataIndex: "id",
            key: "",
            render: (i,index) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip
                            className="ml-5"
                            title={t("operations")}
                            placement="leftBottom"
                        >
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {
                                            index.status !== 2
                                            &&
                                            <Menu.Item>
                                            <div className="flex f-14 flex-align-center pr-1 pl-1" onClick={() => approveAccount(i)}>
                                                <span>Təsdiq</span>
                                            </div>
                                        </Menu.Item>
                                        }
                                       {
                                            index.status !== 3
                                            &&
                                            <SubMenu
                                            key="91"
                                            title={
                                                <span>
                                                    <span>İmtina</span>
                                                </span>
                                            }
                                        >
                                            {
                                                reason.map((r) => {
                                                    return r.type === 0 &&
                                                    (
                                                        <Menu.Item key={r.id}>
                                                            <div className="flex f-14 flex-align-center pr-1 pl-1" onClick={() => rejectAccount(i,r)}>
                                                                <span>{r.name}</span>
                                                            </div>
                                                        </Menu.Item>
                                                    )
                                                }
                                                )
                                            }
                                           
                                        </SubMenu>
                                       }
                                     
                                        <Menu.Item >
                                            <div onClick={() => {
                                                setVisiblePPurchase(true);
                                                setId(i);
                                                }}>
                                                Ətraflı bax
                                                </div>
                                        </Menu.Item>
                                    </Menu>
                                }
                                placement="bottomRight"
                                arrow
                            >
                                <Button shape="circle" className="border-none">
                                    <ArrowRightOutlined />
                                </Button>
                            </Dropdown>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];
    const corporativeColumns = [
        {
            title: "#",
            dataIndex: "tableIndex",
            key: "1",
            width: 60,
        },
        {
            title: 'Şirkətin adı',
            dataIndex: "nameOfCompany",
            key: "2",
        },
        {
            title: 'Telefon',
            dataIndex: "phone",
            key: "3",
        },
        {
            title: 'Paket',
            dataIndex: "packet",
            key: "4",
        },
        {
            title: 'Status',
            dataIndex: "status",
            key: "6",
            render: (i) => {
                console.log(i);
                if (i === 1) {
                    return <span style={{
                        fontSize: '11px',
                        backgroundColor: '#E49F33',
                    }}
                        className="statusBox">Gözləmədə</span>;
                }
                if (i === 2) {
                    return <span style={{
                        fontSize: '11px',
                        backgroundColor:  "#7FBFA4",
                    }}
                        className="statusBox">Təsdiq</span>;
                }
                if (i === 3) {
                    return <span style={{
                        fontSize: '11px',
                        backgroundColor:  "#D24C41",
                    }}
                        className="statusBox">İmtina</span>;
                }
            }
        },
        {
            title: 'Email',
            dataIndex: "email",
            key: "7",

        },
        {
            title: "",
            dataIndex: "id",
            key: "",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip
                            className="ml-5"
                            title={t("operations")}
                            placement="leftBottom"
                        >
                            <Dropdown
                                overlay={
                                    <Menu>
                                        <Menu.Item>
                                            <div className="flex f-14 flex-align-center pr-1 pl-1"  onClick={() => approveAccount(i)}>
                                                <span>Təsdiq</span>
                                            </div>
                                        </Menu.Item>
                                        <SubMenu
                                            key="91"
                                            title={
                                                <span>
                                                    <span>İmtina</span>
                                                </span>
                                            }
                                        >
                                            {
                                                reason.map((r) => {
                                                    return r.type === 0 &&
                                                    (
                                                        <Menu.Item key={r.id}>
                                                            <div className="flex f-14 flex-align-center pr-1 pl-1" onClick={() => rejectAccount(i,r)}>
                                                                <span>{r.name}</span>
                                                            </div>
                                                        </Menu.Item>
                                                    )
                                                }
                                                )
                                            }
                                           
                                        </SubMenu>
                                        <Menu.Item >
                                            <div onClick={() => {
                                                setVisibleCorporative(true);
                                                setCorpoId(i);
                                                }}>
                                                Ətraflı bax
                                                </div>
                                        </Menu.Item>
                                    </Menu>
                                }
                                placement="bottomRight"
                                arrow
                            >
                                <Button shape="circle" className="border-none">
                                    <ArrowRightOutlined />
                                </Button>
                            </Dropdown>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const getIndividualList = () => {
        setSpin(true);
        aictia.get('Account/Individual').then((res) => {
            res.data && setSpin(false);
            console.log(res.data);
            setIndividualPostList(
                res.data.map((d, index) => {
                    return {
                        fullName: d.person.fullName,
                        gmail: d.person.gmail,
                        gender: d.person.gender,
                        specialty: d.person.specialty,
                        packet: d.packetOfPersonal.name,
                        key: index + 1,
                        id: d.id,
                        status: d.status,
                        index,
                        tableIndex: index + 1
                    };
                })
            );
        });
    
    };
    const getCorporativeList = () => {
        setSpin(true);
        aictia.get('Account/Cooperative').then((res) => {
            console.log(res.data);
            res.data && setSpin(false);
            setCorporativePostList(
                res.data.map((d, index) => {
                    return {
                        nameOfCompany: d.nameOfCompany,
                        email: d.email,
                        phone: d.phone,
                        packet: d.packetOfCooperative.name,
                        key: index + 1,
                        id: d.id,
                        status: d.status,
                        index,
                        tableIndex: index + 1
                    };
                })
            );
        });
    }
    const packetList = () => {
        aictia.get('PacketOfPersonal').then((res) => {
            setPacket(
                res.data.map((d, index) => {
                    return {
                        name: d.name,
                        id: d.id,
                    };
                })
            )
        })
    }
    const [form] = Form.useForm();
    const items = [
      { item: 1, code: "MMC" },
      { item: 2, code: "QSC" },
      { item: 3, code: "ASC" },
  ];
  
  
  const ownership = [
    { ower: 1, code: "Mikro" },
    { ower: 2, code: "Kiçik" },
    { ower: 3, code: "Orta" },
    { ower: 4, code: "İri" },
  ];
  
    const clearFilter = () => {
      form.resetFields()
      getIndividualList()
      getCorporativeList()
    //   setFilterData([])
    //   setIndividualFilter([])
    //   setIndividualEmpty(false)
    //   setEmptyData(false)
    }
    const [filterData, setFilterData] = React.useState([])
    const [individualFilter, setIndividualFilter] = React.useState([])
    const [individualEmpty, setIndividualEmpty] = React.useState(false)
    const [emptyData, setEmptyData] = React.useState(false)
    const onSearchCorporative = (values) =>{
      let obj = {}
      console.log(values);
      if (values.CountryId) { // endDate varsa
          obj['CountryId'] = values.CountryId
        }
        if (values.TypeOfCompany) { // endDate varsa
          obj['TypeOfCompany'] = values.TypeOfCompany
        }
        if (values.BusinessEntity) { // endDate varsa
          obj['BusinessEntity'] = values.BusinessEntity
        }
  
      if (Object.keys(obj).length === 0 && obj.constructor === Object) {
          console.log('obj is empty');
          return;
        }
      if(obj !== {}){
         aictia.get('https://api.aictia.org/api/root/Account/Cooperative' , { params: obj }).then((res) => {
          if(res.data.length === 0){
                console.log('data is empty');
                setCorporativePostList([])
                setEmptyData(true)
          }else{
              console.log(res.data);
              setCorporativePostList( 
                res.data.map((d, index) => {
                return {
                    nameOfCompany: d.nameOfCompany,
                    email: d.email,
                    phone: d.phone,
                    packet: d.packetOfCooperative.name,
                    key: index + 1,
                    id: d.id,
                    status: d.status,
                    index,
                    tableIndex: index + 1
                };
            }))
              setEmptyData(false)
          }
      });
  }
    }
    const onSearchIndividual = (values) =>{
      let obj = {}
      if (values.StartCreationDateOfAccount) { // tarix varsa
          obj['StartCreationDateOfAccount'] = moment(values.StartCreationDateOfAccount).format('YYYY-MM-DD')
        }
        if (values.EndCreationDateOfAccount) { // tarix varsa
          obj['EndCreationDateOfAccount'] = moment(values.EndCreationDateOfAccount).format('YYYY-MM-DD')
        }
        if (values.StatusOfAccount) { // status varsa
          obj['StatusOfAccount'] = values.StatusOfAccount
        }
        const queryParams = values.packet?.map((packetId) => `PacketList=${packetId}`).join('&');
      if (Object.keys(obj).length === 0 && obj.constructor === Object) {
          console.log('obj is empty');
          return;
        }
      if(obj !== {}){
         aictia.get(`https://api.aictia.org/api/root/Account/Individual?${queryParams}` , { params: obj }).then((res) => {
          if(res.data.length === 0){
                console.log('data is empty');
                setIndividualPostList([])
          }else{
              setIndividualPostList( 
                res.data.map((d, index) => {
                  return {
                      fullName: d.person.fullName,
                      gmail: d.person.gmail,
                      gender: d.person.gender,
                      specialty: d.person.specialty,
                      packet: d.packetOfPersonal.name,
                      key: index + 1,
                      id: d.id,
                      status: d.status,
                      index,
                      tableIndex: index + 1
                  };
              }))
              setIndividualEmpty(false)
          }
      });
  }
    }
    
    const countryList = async () => {
        await axios.get('https://api.aictia.org/api/Country').then((res) => {
            console.log(res.data);
            setCountry(res.data)
        })
      }
  
//  const data = 'Corporative'
//   ?
//   filterData.length !== 0 || emptyData
//     ? filterData
//     : postlist
//   : individualFilter.length !== 0 || individualEmpty 
//     ? individualFilter
//     : postlist
    useEffect(() => {
       
        packetList();
        reasonOfReject();
        getIndividualList();
        getCorporativeList();
        countryList();
    }, []);
console.log(corporativePostList);
    return (
        <div>
            <div className="border page-heading flex p-2 mt-0 bg-white">
        <div className="page-name">
        <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Müraciətlər</span>
        </div>
      </div>
      <div className="position-relative mt-15">
        <div className="position-absolute w-100 tab-section">
        <Tabs animated={true} defaultActiveKey="1" className="w-100">
              <TabPane tab={t("Fərdi Müraciətlər")} key="1">
                <div  className={'w-100'}>
                <Form layout="vertical" onFinish={onSearchIndividual} form={form}>
                <div className="commontask bg-white px-2 pt-15">
                  <Row className={'mt-5'} gutter={[16, 8]}>
                  <Col md={5} sm={12} xs={24}>
                      <Form.Item
                        name={'StartCreationDateOfAccount'}
                      >
                        <DatePicker
                          format={"DD-MM-YYYY"}
                          placeholder={'Tarixdən'}
                          className="w-100"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={5} sm={12} xs={24}>
                      <Form.Item
                        name={'EndCreationDateOfAccount'}
                      >
                        <DatePicker
                          format={"DD-MM-YYYY"}
                          placeholder={'Tarixə'}
                          className="w-100"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={5} sm={12} xs={24}>
                      <Form.Item
                        name={"StatusOfAccount"}
                      >
                        <Select placeholder='Status'>

                          <Option key={1} value={1}>
                          Gözləmədə
                          </Option>
                          <Option key={2} value={2}>
                          Təsdiq
                          </Option>
                          <Option key={3} value={3}>
                            Imtina
                          </Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={5} sm={12} xs={24}>
                      <Form.Item
                        name={"packet"}
                      // rules={[noWhitespace(t("inputError"))]}
                      >
                        <Select placeholder='Paket' mode="multiple">
                          {
                            packet.map((item, index) => {
                              return (
                                <Option key={index} value={item.id}>
                                  {item.name}
                                </Option>
                              )
                            })
                          }

                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={2} sm={12} xs={24}>
                      <Form.Item
                        name={" "}
                      >
                        <Button type="primary" size={'large'} className="w-100 f-13" htmlType="submit">
                          Axtar
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col md={2} sm={12} xs={24}>
                      <Form.Item
                        name={" "}
                      >
                        <Button type="primary" size={'large'} onClick={() => { clearFilter() }} className="w-100" htmlType="submit">
                          <ClearOutlined />
                        </Button>
                      </Form.Item>
                    </Col>

                  </Row>
                </div>
              </Form>
                </div>
                <Table
                size="small"
                className="bg-white animated fadeIn"
                columns={individualColumns}
                dataSource={convertColumns(individualPostList, cols)}
                pagination={{
                  pageSize: 15,
                  current_page: 1,
                }}
              />
              </TabPane>
              <TabPane tab={t("Korporativ Müraciətlər")} key="2">
                <div className={'w-100'}>
                <Form layout="vertical" onFinish={onSearchCorporative} form={form}>
                <div className="commontask bg-white px-2 pt-15">
                  <Row className={'mt-5'} gutter={[16, 8]}>
                    <Col md={5} sm={12} xs={24}>
                    <Form.Item
                       name={"CountryId"}
                   >
                     <Select placeholder='Ölkə'>
                  {country.map((pc, index) => {
                  return (
                    <Option key={index} value={pc.id}>
                      {pc.name}
                    </Option>
                  );
                })}
                     </Select>
                   </Form.Item>
                    </Col>
                    <Col md={5} sm={12} xs={24}>
                    <Form.Item
                       name={"TypeOfCompany"}
                   >
                     <Select placeholder='Təşkilati-hüquqi forması'>
                  {items.map((pc, index) => {
                  return (
                    <Option key={index} value={pc.item}>
                      {pc.code}
                    </Option>
                  );
                })}
                     </Select>
                   </Form.Item>
                    </Col>
                  
                    <Col md={5} sm={12} xs={24}>
                    <Form.Item
                       name={"BusinessEntity"}
                   >
                     <Select placeholder='Sahibkarlıq subyekti'>
                  {ownership.map((pc, index) => {
                  return (
                    <Option key={index} value={pc.ower}>
                      {pc.code}
                    </Option>
                  );
                })}
                     </Select>
                   </Form.Item>
                    </Col>
                    <Col md={2} sm={12} xs={24}>
                      <Form.Item
                        name={" "}
                      >
                        <Button type="primary" size={'large'} className="w-100 f-13" htmlType="submit">
                          Axtar
                        </Button>
                      </Form.Item>
                    </Col>

                    <Col md={2} sm={12} xs={24}>
                      <Form.Item
                        name={" "}
                      >
                        <Button type="primary" size={'large'} onClick={() => { clearFilter() }} className="w-100" htmlType="submit">
                          <ClearOutlined />
                        </Button>
                      </Form.Item>
                    </Col>

                  </Row>
                </div>
              </Form>
                </div>
                <Table
                size="small"
                className="bg-white animated fadeIn"
                columns={corporativeColumns}
                dataSource={convertColumns(corporativePostList, cols)}
                pagination={{
                  pageSize: 15,
                  current_page: 1,
                }}
              />
              </TabPane>
          </Tabs>
        </div>
        </div>
        <Modal
        title={t("detailedInfo")}
        centered
        className="addTaskModal"
        onOk={() => setVisiblePPurchase(false)}
        onCancel={() => setVisiblePPurchase(false)}
        visible={visiblePPurchase}
        footer={null}
      >
        <PersonDetails
          id={id}
          visiblePPurchase={visiblePPurchase}
          setVisiblePPurchase={setVisiblePPurchase}
        />
      </Modal>
      <Modal
        title={t("detailedInfo")}
        centered
        className="addTaskModal"
        onOk={() => setVisibleCorporative(false)}
        onCancel={() => setVisibleCorporative(false)}
        visible={visibleCorporative}
        footer={null}
      >
        <PersonCorpoDetails
          id={corpoId}
          visiblePPurchase={visibleCorporative}
          setVisiblePPurchase={setVisibleCorporative}
        />
      </Modal>
        </div>
      
    );
}

export default connect(null, { notify })(IndividualMembers)
