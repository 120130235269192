import {
  SET_USER_ERROR,
  LOADING_ON,
  LOADING_OFF,
  SET_USER_LOGGED_IN,
  LOG_OUT,
  SET_OPTIONS,
  SET_LANG,
  SET_NOTIFICATION,
  SET_STOCK, GET_ARTESIAN, GET_ARTESIAN_ERROR, GET_STORAGE, GET_STORAGE_ERROR
} from "./../types";
import agros, {aictia} from "./../../const/api";
import history from "./../../const/history";
import { ourls } from "../../utils/options";

export const getUserData = () => async (dispatch) => {
  dispatch({ type: LOADING_ON });
  let token = localStorage.getItem("access_token")
  if (token !== undefined){
    console.log(JSON.parse(localStorage.getItem('user')));
    dispatch({
      type: SET_USER_LOGGED_IN,
      payload: {
        token:localStorage.getItem('access_token'),
        user:JSON.parse(localStorage.getItem('user'))
      },
    });
    dispatch({ type: LOADING_OFF });
  }
  if (token === undefined){
    dispatch({
      type: LOG_OUT,
    });
    dispatch({ type: LOADING_OFF });
  }
};

export const logInUser = (Username, Password) => async (dispatch) => {
  if (Username.trim().length === 0 || Password.trim().length === 0) {
    dispatch({
      type: SET_USER_ERROR,
      payload: { message: "İstifadəçi adı və şifrə daxil edilməlidir" },
    });
  } else {
    dispatch({ type: LOADING_ON });
    await aictia
      .post('auth/login', JSON.stringify({ Username, Password }))
      .then((res) => {
        console.log(res.data);
        //localStorage.setItem("exp", res.data.expires_in*1000);
        let date =  Date.now();
        localStorage.setItem("now", date);
        localStorage.setItem("access_token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.username));
        console.log(JSON.parse(localStorage.getItem('user')))
        console.log('salam');
        dispatch({
          type: SET_USER_LOGGED_IN,
          payload: {
            token:localStorage.getItem('access_token'),
            user:JSON.parse(localStorage.getItem('user'))
          },
        });
        history.push("#/");
      })
      .catch((error) => {
        console.log(error)
        dispatch({
          type: SET_USER_ERROR,
          payload: { message: "İstifadəçi adı və ya şifrə yanlışdır" },
        });
      })
      .finally(() => {
        dispatch({ type: LOADING_OFF });
      });
  }
};

export const toggleLoading = (payload) => ({
  type: payload ? LOADING_ON : LOADING_OFF,
});

export const logOut = () => ({
  type: LOG_OUT,
});

export const getAllOptions = (keys, props) => async (dispatch) => {
  let obj = {};
  const ops = new Promise((resolve, reject) => {
    let ind = 0;
    keys.forEach(async (key) => {
      if (!props[key].length) {
        await agros.get(ourls[key]).then((res) => {
          obj[key] = res.data;
          ind++;
        });
      } else {
        ind++;
      }
      if (ind === keys.length) {
        resolve();
      }
    });
  });

  ops.then(() => {
    dispatch({
      type: SET_OPTIONS,
      payload: obj,
    });
  });
};

export const getOptions = (keys, props, lang) => async (dispatch) => {
  let obj = { ...props };
  const ops = new Promise((resolve, reject) => {
    let ind = 0;
    keys.forEach(async (key) => {
      if (!props[lang][key].length) {
        await agros.get(ourls[key]).then((res) => {
          obj[lang][key] = res.data;
          ind++;
        });
      } else {
        ind++;
      }
      if (ind === keys.length) {
        resolve();
      }
    });
  });

  ops.then(() => {
    dispatch({
      type: SET_OPTIONS,
      payload: obj,
    });
  });
};

export const changeLanguage = (payload) => {
  return {
    type: SET_LANG,
    payload,
  };
};

export const notify = (description, isHappy) => {
  return {
    type: SET_NOTIFICATION,
    payload: { description, isHappy },
  };
};

export const getArtesian = () => async (dispatch) => {
  agros
      .get("Services/Artesian")
      .then((res) => {
        dispatch({
          type: GET_ARTESIAN,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ARTESIAN_ERROR,
          payload: { message: "Xəta baş verdi" },
        });
      });
};
export const getStorage = (search) => async (dispatch) => {
  agros
      .get(`Services/Warehouse` , {params:
          search ? {
            warehouseName:search
          } : null
      })
      .then((res) => {
        dispatch({
          type: GET_STORAGE,
          payload: res.data
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_STORAGE_ERROR,
          payload: { message: "Xəta baş verdi" },
        });
      });
};